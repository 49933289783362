import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import moment from 'moment';
import {
  SALVA_USUARIO_PLATAFORMA,
  SALVA_USUARIO_PLATAFORMA_LITE,
  INICIA_USUARIO_PLATAFORMA,
  BUSCA_USUARIO_PLATAFORMA_EDICAO,
  REMOVE_USUARIO_PLATAFORMA,
  REMOVE_CLIENTE_PESSOA_PLATAFORMA,
  REMOVE_EVENTO_PESSOA_PERFIL,
  LISTA_PERFIS_POR_CLIENTE,
  LISTA_USUARIO_PLATAFORMA,
  BUSCA_PESSOA_BASE_UZER,
  BUSCA_PESSOA_BASE_UZER_LITE,
  EXIBE_DETALHE_ESTRUTURA_LOCAL,
  OBTEM_IMAGEM_ARRAY_PESSOAS,
  BUSCA_USUARIO_PLATAFORMA_LITE_EDICAO,
  RELATORIO_ACESSO_UNICO_PESSOA,
  APLICA_FILTRO,
  APLICA_FILTRO_COMPANY,
  ENVIAR_SMS_PESSOA_EVENTO,
  CANCELA_EDICAO,
  INICIO_PESSOAS_EVENTO
} from "constants/ActionTypes";
import requisicaoApi from 'util/Api';
import requisicaoApiV2 from 'util/ApiV2';
import reportUzerPass from 'util/ReportUzerPass';
import { userSignOut } from "actions/Auth";
import {
  showAuthMessage,
  exibirMensagemErro,
  salvaUsuarioPlataformaSucesso,
  removeUsuarioPlataformaSucesso,
  removeEventoPessoaPerfilSucesso,
  inicioUsuarioPlataformaSucesso,
  iniciaUsuarioPlataforma,
  buscaUsuarioPlataformaEdicaoSucesso,
  buscaUsuarioPlataformaLiteEdicaoSucesso,
  listaPerfisSucesso,
  listaUsuarioPlataformaSucesso,
  exibeDetalheEstruturaLocalSucesso,
  abortaOperacao,
  obtemImagemArrayPessoasSucesso,
  aplicarFiltroSucesso,
  iniciaPessoasEventosSucesso,
  relatorioAcessoUnicoPessoasEventosSucesso,
  enviarSmsParaEventoPessoaSucesso
} from "actions/UsuarioPlataforma";
import { limpaValidacoes } from "actions/Global";

/*#################################################################################################################*/
/* --------------------------------------------- Requests do tipo GET ---------------------------------------------*/

const getUsuariosPlataforma = async () =>
  await requisicaoApi.get('/usuariosPlataforma')
    .then(resposta => resposta)
    .catch(error => error);

const getRelatorioAcessoUnicoPessoasEventos = async (requestBody) =>
  await reportUzerPass.post('/v1/relatorioscontrollers/acesso-unico', requestBody.relatorioAcesso, {
    headers: {
      accept: "application/json",
      apiKey: "$2a$16$jnPa1Yl0rslEH1kkmT4a1.7UgfPp6S0HHVQx7H.Ohy7q3L1jZJQz."
    }
  })
  .then(resposta => resposta)
  .catch(error => error);    

const getPessoasEventos = async (idEvento) =>
  await requisicaoApiV2.get('/v1/eventopessoa/listar/evento/' + idEvento)
  .then(resposta => resposta)
  .catch(error => error);    

const enviarSmsParaEventoPessoa = async (idEvento) =>
await requisicaoApiV2.post('/v1/eventopessoa/send-sms/evento/' + idEvento)
.then(resposta => resposta)
.catch(error => error); 

const getPessoasEventosFiltro = async (filtroAplicar) =>
await requisicaoApiV2.post('/v1/eventopessoa/listar/evento/filtro', filtroAplicar)
  .then(resposta => resposta)
  .catch(error => error);    

const getUsuariosPlataformaComFiltro = async (filtroAplicar) =>
await requisicaoApi.get('/usuariosPlataforma/Novo?Nome=' + filtroAplicar)
  .then(resposta => resposta)
  .catch(error => error);
  

const getPaises = async () =>
  await requisicaoApi.get('/util/paises')
    .then(resposta => resposta)
    .catch(error => error);

const getPaisesTiposDocumentoEstrangeiro = async () =>
  await requisicaoApi.get('/util/paisesTiposDocumentoEstrangeiro')
    .then(resposta => resposta)
    .catch(error => error);

const getLocaisVinculosNovos = async () =>
  await requisicaoApi.get('/usuariosPlataforma/locaisVinculosNovos')
    .then(resposta => resposta)
    .catch(error => error);

const getAcessoAmbienteLocal = async (id) =>
  await requisicaoApi.get('/gestaoAcesso/local/' + id + '/' + false)
    .then(resposta => resposta)
    .catch(error => error);

const getUsuarioPlataformaEdicao = async (id) =>
  await requisicaoApi.get('/usuariosPlataforma/' + id)
    .then(resposta => resposta)
    .catch(error => error);

const getUsuarioPlataformaLiteEdicao = async (id) =>
  await requisicaoApiV2.get('/v1/eventopessoa/' + id)
    .then(resposta => resposta)
    .catch(error => error);

const getThumbnailPessoa = async () =>
  await requisicaoApi.get('/usuariosPlataforma/listaThumb')
    .then(resposta => resposta)
    .catch(error => error);

const getPessoaPorCpf = async (cpf) =>
  await requisicaoApi.get('/usuariosPlataforma/cpf/' + cpf)
    .then(resposta => resposta)
    .catch(error => error);

const getPessoaPorCpfLite = async (cpf, idEvento) =>
  await requisicaoApiV2.get(`/v1/eventopessoa/evento/${idEvento}/documento/${cpf}`)
    .then(resposta => resposta)
    .catch(error => error);

const getClientes = async () =>
  await requisicaoApi.get('/clientes')
    .then(resposta => resposta)
    .catch(error => error);

const getListaPerfisPorCliente = async (cliente) =>
  await requisicaoApi.get('/perfis/cliente/' + cliente)
    .then(resposta => resposta)
    .catch(error => error);

const getPerfis = async (cliente) =>
  await requisicaoApi.get('/perfis?ignoraClienteFixado=true')
    .then(resposta => resposta)
    .catch(error => error);

const getPerfisPermanentes = async (cliente) =>
  await requisicaoApi.get('/perfis/permanente/cliente/' + cliente)
    .then(resposta => resposta)
    .catch(error => error);

const getAmbientesPerfilTemporario = async () =>
  await requisicaoApi.get('/util/ambientesAcessoTemporario')
    .then(resposta => resposta)
    .catch(error => error);

const getArvoreClientePessoa = async (idClientePessoa, idCliente) =>
  await requisicaoApi.get('/clientes/arvoreClientePessoa/' + idClientePessoa + '/' + idCliente)
    .then(resposta => resposta)
    .catch(error => error);

const getListaUsuarioPlataforma = async (codigoFiltro, cpfFiltro, nomeFiltro) =>
  await requisicaoApi.get('/usuariosPlataforma?Codigo=' + codigoFiltro + '&Cpf=' + cpfFiltro + '&Nome=' + nomeFiltro)
    .then(resposta => resposta)
    .catch(error => error);

const getLocaisControlados = async (idCliente) =>
  await requisicaoApi.get('/locais/complemento/' + idCliente)
    .then(resposta => resposta)
    .catch(error => error);

/*#################################################################################################################*/
/* ------------------------------------- Requests do tipo POST, PUT E DELETE --------------------------------------*/

const adicionaUsuarioPlataformaRequest = async (usuarioPlataforma) => await requisicaoApi.post('/usuariosPlataforma',
  {
    "dataNascimentoStr": usuarioPlataforma.tipoPessoa === 'FISICA' ? usuarioPlataforma.dataNascimentoStr : '',
    "tipoPessoa": usuarioPlataforma.tipoPessoa,
    "nomePessoa": usuarioPlataforma.tipoPessoa === 'FISICA' ? usuarioPlataforma.nomePessoa : '',
    "numeroCpf": usuarioPlataforma.tipoPessoa === 'FISICA' ? usuarioPlataforma.numeroCpf : '',
    "nomeMae": usuarioPlataforma.tipoPessoa === 'FISICA' ? usuarioPlataforma.nomeMae : '',
    "sexo": usuarioPlataforma.tipoPessoa === 'FISICA' ? usuarioPlataforma.sexo : '',
    "imagem": usuarioPlataforma.imagem,
    "thumbnail": usuarioPlataforma.thumbnail,
    "estadoCivil": usuarioPlataforma.tipoPessoa === 'FISICA' ? usuarioPlataforma.estadoCivil : '',
    "tipoUsuario": "PLATAFORMA_ACESSO",
    "ativo": true,
    "id": usuarioPlataforma.id,
    "idCliente": usuarioPlataforma.idCliente,
    "site": usuarioPlataforma.site,
    "cep": usuarioPlataforma.cep,
    "logradouro": usuarioPlataforma.logradouro,
    "numero": usuarioPlataforma.numero,
    "complemento": usuarioPlataforma.complemento,
    "semNumero": usuarioPlataforma.semNumero,
    "indiDuploCheck": usuarioPlataforma.indiDuploCheck,
    "bairro": usuarioPlataforma.bairro,
    "uf": usuarioPlataforma.uf,
    "nomeUf": usuarioPlataforma.nomeUf,
    "idMunicipio": usuarioPlataforma.idMunicipio,
    "codigoIbge": usuarioPlataforma.codigoIbge,
    "nomeMunicipio": usuarioPlataforma.nomeMunicipio,
    "situacaoPessoaCliente": usuarioPlataforma.situacaoPessoaCliente,
    "idPaisTipoDocumento": usuarioPlataforma.tipoPessoa === 'FISICA' ? usuarioPlataforma.idPaisTipoDocumento : 0,
    "numeroDocumentoEstrangeiro": usuarioPlataforma.tipoPessoa === 'FISICA' ? usuarioPlataforma.numeroDocumentoEstrangeiro : '',
    "perfisVinculados": usuarioPlataforma.listaDePerfisVinculados,
    "telefones": usuarioPlataforma.telefones,
    "emails": usuarioPlataforma.emails,
    "codigosPerfis": usuarioPlataforma.tipoPessoa === 'FISICA' ? usuarioPlataforma.codigosPerfis.length === 0 ? [] : usuarioPlataforma.codigosPerfis : [],
    "complementosPessoa": usuarioPlataforma.complementosPessoa.length === 0 ? [] : usuarioPlataforma.complementosPessoa,
    "listaVinculosLocalPessoa": usuarioPlataforma.listaVinculosLocalPessoa.length === 0 ? [] : usuarioPlataforma.listaVinculosLocalPessoa,
    "listaAmbientesPessoa": usuarioPlataforma.listaAmbientesPessoa.length === 0 ? [] : usuarioPlataforma.listaAmbientesPessoa,
    "vinculosPessoa": {
      "dependentes": usuarioPlataforma.dependentes.length === 0 ? [] : usuarioPlataforma.dependentes,
      "responsaveis": usuarioPlataforma.responsaveis.length === 0 ? [] : usuarioPlataforma.responsaveis,
    },
    "numeroCnpj": usuarioPlataforma.tipoPessoa === 'JURIDICA' ? usuarioPlataforma.numeroCnpj : '',
    "razaoSocial": usuarioPlataforma.tipoPessoa === 'JURIDICA' ? usuarioPlataforma.razaoSocial : '',
    "nomeFanstasia": usuarioPlataforma.tipoPessoa === 'JURIDICA' ? usuarioPlataforma.nomeFanstasia : '',
    "inscricaoEstadual": usuarioPlataforma.tipoPessoa === 'JURIDICA' ? usuarioPlataforma.inscricaoEstadual : '',
    "isentoInscricaoEstadual": usuarioPlataforma.tipoPessoa === 'JURIDICA' ? usuarioPlataforma.isentoInscricaoEstadual : false,
    "inscricaoMunicipal": usuarioPlataforma.tipoPessoa === 'JURIDICA' ? usuarioPlataforma.inscricaoMunicipal : '',
    "isentoInscricaoMunicipal": usuarioPlataforma.tipoPessoa === 'JURIDICA' ? usuarioPlataforma.isentoInscricaoMunicipal : false,
    "sincronizar": usuarioPlataforma.sincronizar
  })
  .then(resposta => resposta)
  .catch(error => error);

const adicionaUsuarioPlataformaLiteRequest = async (usuarioPlataforma) => await requisicaoApiV2.post('/v1/eventopessoa',
  {
    "idEvento": usuarioPlataforma.idEvento,
    "idPessoa": usuarioPlataforma.idPessoa,
    "nomePessoa": usuarioPlataforma.nomePessoa,
    "imagem": usuarioPlataforma.imagem,
    "imagemThumbnail": usuarioPlataforma.thumbnail,
    "telefone": usuarioPlataforma.telefoneCadastroRapido,
    "cpf": usuarioPlataforma.numeroCpf,
    "sexo": usuarioPlataforma.sexo,
    "documentoEstrangeiro": usuarioPlataforma.documentoEstrangeiro,
    "estrangeiro": usuarioPlataforma.estrangeiro,
    "perfis": usuarioPlataforma.perfis,
    "dataNascimento": moment(usuarioPlataforma.dataNascimentoStr,'DD/MM/YYYY'),
    "sincronizar": usuarioPlataforma.sincronizar
  })
  .then(resposta => resposta)
  .catch(error => error);

const atualizaUsuarioPlataformaRequest = async (usuarioPlataforma) => await requisicaoApi.put('/usuariosPlataforma',
  {
    "dataNascimentoStr": usuarioPlataforma.tipoPessoa === 'FISICA' ? usuarioPlataforma.dataNascimentoStr : '',
    "tipoPessoa": usuarioPlataforma.tipoPessoa,
    "nomePessoa": usuarioPlataforma.tipoPessoa === 'FISICA' ? usuarioPlataforma.nomePessoa : '',
    "numeroCpf": usuarioPlataforma.tipoPessoa === 'FISICA' ? usuarioPlataforma.numeroCpf : '',
    "nomeMae": usuarioPlataforma.tipoPessoa === 'FISICA' ? usuarioPlataforma.nomeMae : '',
    "sexo": usuarioPlataforma.tipoPessoa === 'FISICA' ? usuarioPlataforma.sexo : '',
    "imagem": usuarioPlataforma.imagem,
    "thumbnail": usuarioPlataforma.thumbnail,
    "estadoCivil": usuarioPlataforma.tipoPessoa === 'FISICA' ? usuarioPlataforma.estadoCivil : '',
    "tipoUsuario": "PLATAFORMA_ACESSO",
    "ativo": true,
    "id": usuarioPlataforma.id,
    "idCliente": usuarioPlataforma.idCliente,
    "site": usuarioPlataforma.site,
    "cep": usuarioPlataforma.cep,
    "logradouro": usuarioPlataforma.logradouro,
    "numero": usuarioPlataforma.numero,
    "complemento": usuarioPlataforma.complemento,
    "semNumero": usuarioPlataforma.semNumero,
    "indiDuploCheck": usuarioPlataforma.indiDuploCheck,
    "bairro": usuarioPlataforma.bairro,
    "uf": usuarioPlataforma.uf,
    "nomeUf": usuarioPlataforma.nomeUf,
    "idMunicipio": usuarioPlataforma.idMunicipio,
    "codigoIbge": usuarioPlataforma.codigoIbge,
    "nomeMunicipio": usuarioPlataforma.nomeMunicipio,
    "situacaoPessoaCliente": usuarioPlataforma.situacaoPessoaCliente,
    "idPaisTipoDocumento": usuarioPlataforma.tipoPessoa === 'FISICA' ? usuarioPlataforma.idPaisTipoDocumento : 0,
    "numeroDocumentoEstrangeiro": usuarioPlataforma.tipoPessoa === 'FISICA' ? usuarioPlataforma.numeroDocumentoEstrangeiro : '',
    "perfisVinculados": usuarioPlataforma.listaDePerfisVinculados,
    "telefones": usuarioPlataforma.telefones,
    "emails": usuarioPlataforma.emails,
    "codigosPerfis": usuarioPlataforma.tipoPessoa === 'FISICA' ? usuarioPlataforma.codigosPerfis.length === 0 ? [] : usuarioPlataforma.codigosPerfis : [],
    "complementosPessoa": usuarioPlataforma.complementosPessoa.length === 0 ? [] : usuarioPlataforma.complementosPessoa,
    "listaVinculosLocalPessoa": usuarioPlataforma.listaVinculosLocalPessoa.length === 0 ? [] : usuarioPlataforma.listaVinculosLocalPessoa,
    "listaAmbientesPessoa": usuarioPlataforma.listaAmbientesPessoa.length === 0 ? [] : usuarioPlataforma.listaAmbientesPessoa,
    "vinculosPessoa": {
      "dependentes": usuarioPlataforma.dependentes.length === 0 ? [] : usuarioPlataforma.dependentes,
      "responsaveis": usuarioPlataforma.responsaveis.length === 0 ? [] : usuarioPlataforma.responsaveis,
    },
    "numeroCnpj": usuarioPlataforma.tipoPessoa === 'JURIDICA' ? usuarioPlataforma.numeroCnpj : '',
    "razaoSocial": usuarioPlataforma.tipoPessoa === 'JURIDICA' ? usuarioPlataforma.razaoSocial : '',
    "nomeFanstasia": usuarioPlataforma.tipoPessoa === 'JURIDICA' ? usuarioPlataforma.nomeFanstasia : '',
    "inscricaoEstadual": usuarioPlataforma.tipoPessoa === 'JURIDICA' ? usuarioPlataforma.inscricaoEstadual : '',
    "isentoInscricaoEstadual": usuarioPlataforma.tipoPessoa === 'JURIDICA' ? usuarioPlataforma.isentoInscricaoEstadual : false,
    "inscricaoMunicipal": usuarioPlataforma.tipoPessoa === 'JURIDICA' ? usuarioPlataforma.inscricaoMunicipal : '',
    "isentoInscricaoMunicipal": usuarioPlataforma.tipoPessoa === 'JURIDICA' ? usuarioPlataforma.isentoInscricaoMunicipal : false,
    "houveAlteracaoFoto": usuarioPlataforma.houveAlteracaoFoto,
    "sincronizar": usuarioPlataforma.sincronizar
  })
  .then(resposta => resposta)
  .catch(error => error);

const atualizaUsuarioPlataformaLiteRequest = async (usuarioPlataforma) => await requisicaoApiV2.put('/v1/eventopessoa',
  {
    "idEventoPessoa": usuarioPlataforma.id,
    "idEvento": usuarioPlataforma.idEvento,
    "idPessoa": usuarioPlataforma.idPessoa,
    "nomePessoa": usuarioPlataforma.nomePessoa,
    "imagem": usuarioPlataforma.imagem,
    "imagemThumbnail": usuarioPlataforma.thumbnail,
    "telefone": usuarioPlataforma.telefoneCadastroRapido,
    "cpf": usuarioPlataforma.numeroCpf,
    "sexo": usuarioPlataforma.sexo,
    "documentoEstrangeiro": usuarioPlataforma.documentoEstrangeiro,
    "estrangeiro": usuarioPlataforma.estrangeiro,
    "perfis": usuarioPlataforma.perfis,
    "dataNascimento": moment(usuarioPlataforma.dataNascimentoStr,'DD/MM/YYYY'),
    "sincronizar": usuarioPlataforma.sincronizar
  })
  .then(resposta => resposta)
  .catch(error => error);

const deleteUsuarioPlataformaRequest = async (id) =>
  await requisicaoApi.delete('/usuariosPlataforma?id=' + id)
    .then(resposta => resposta)
    .catch(error => error);

const deleteClientePessoaRequest = async (id, idCliente) =>
  await requisicaoApi.delete('/usuariosPlataforma/clientepessoa/' + id + '/' + idCliente)
    .then(resposta => resposta)
    .catch(error => error);
const deleteEventoPessoaPerfil = async (id) =>
    await requisicaoApiV2.delete('/v1/eventopessoa/' + id)
      .then(resposta => resposta)
      .catch(error => error);
  
/*#################################################################################################################*/
/* --------------------------------------------- Functions do tipo GET --------------------------------------------*/
/*#################################################################################################################*/

function* fnIniciaUsuarioPlataforma() {
  try {
    const listagemGrid = yield call(getUsuariosPlataformaComFiltro, '');
    const clienteFixo = localStorage.getItem('idClienteFixado');

    if (!listagemGrid.isAxiosError && listagemGrid.data) {
      const clientes = yield call(getClientes);
      ////const arvore = yield call(getArvoreClientePessoa, 0, clienteFixo);
      const locais = yield call(getLocaisControlados, clienteFixo);

      if (clientes.data) {
        const perfis = yield call(getPerfisPermanentes, clienteFixo);
        const paises = yield call(getPaises)
        const paisesListaDocumentos = yield call(getPaisesTiposDocumentoEstrangeiro)
        const locaisVinculosNovos = yield call(getLocaisVinculosNovos)
        const ambientesPerfilTemporario = yield call(getAmbientesPerfilTemporario);

        if (perfis.data) {
          yield put(inicioUsuarioPlataformaSucesso(listagemGrid.data, clientes.data, perfis.data, locais.data, paises.data, paisesListaDocumentos.data, locaisVinculosNovos.data, ambientesPerfilTemporario.data));
          yield put(limpaValidacoes());
        }
      }
    }
    else {
      if (listagemGrid.response.status === 401 && listagemGrid.response.config.xsrfHeaderName === "X-XSRF-TOKEN") {
        yield put(userSignOut());
      }
      else {
        yield put(showAuthMessage('Ocorreu um erro ao listar usuariosPlataforma'));
      }
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnEnviaSmsPessoaEvento({payload}) {
  const local = payload.idLocal;
  const response = yield call(enviarSmsParaEventoPessoa, local);
  if(!response.isAxiosError) {
    yield put(iniciaUsuarioPlataforma());
    yield put(enviarSmsParaEventoPessoaSucesso('Sms enviado com sucesso!'));
  }
}

function* fnRelatorioAcessoUnicoPessoasEventos({ payload }) {
  try {
    const relatorioAcesso = yield call(getRelatorioAcessoUnicoPessoasEventos, payload);

    if (!relatorioAcesso.isAxiosError && relatorioAcesso.data) {
      yield put(relatorioAcessoUnicoPessoasEventosSucesso(relatorioAcesso.data));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnInicioPessoasEvento({ payload }) {
  try {
    const local = payload.idLocal;
    const listagemGrid = yield call(getPessoasEventos, local);

    if(!listagemGrid.isAxiosError && listagemGrid.data) {
      yield put(iniciaPessoasEventosSucesso(listagemGrid.data, local));
    }
/*       else {
      if(listagemGrid.response.status === 401 && listagemGrid.response.config.xsrfHeaderName === "X-XSRF-TOKEN")
      {
        yield put(userSignOut());
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao listar categorias'));
      }
    }        */     
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnBuscaPessoaBaseUzer({ payload }) {
  try {
    const cpf = payload;
    const usuario = yield call(getPessoaPorCpf, cpf);

    if (!usuario.isAxiosError && usuario.data) {
      const arvore = yield call(getArvoreClientePessoa, usuario.data.id, usuario.data.idCliente);

      if (arvore.data) {
        yield put(buscaUsuarioPlataformaEdicaoSucesso(usuario.data, arvore.data));
        yield put(limpaValidacoes());
      }
      else {
        yield put(showAuthMessage('Ocorreu um erro ao retornar a Árvore de Acessos da pessoa: ' + cpf));
      }
    }
    else {
      if (usuario.status === 204) {
        yield put(abortaOperacao());
      }
      else {
        yield put(showAuthMessage('Ocorreu um erro ao retornar os dados do usuario: ' + cpf));
      }
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnBuscaPessoaLiteBaseUzer({ payload }) {
  try {
    const cpf = payload.cpf;
    const idEvento = payload.idEvento;
    const usuario = yield call(getPessoaPorCpfLite, cpf, idEvento);

    if (!usuario.isAxiosError && usuario.data) {
        yield put(buscaUsuarioPlataformaLiteEdicaoSucesso(usuario.data));
        yield put(limpaValidacoes());
    }
    else {
      if (usuario.status === 204) {
        yield put(abortaOperacao());
      }
      else {
        yield put(showAuthMessage('Ocorreu um erro ao retornar os dados do usuario: ' + cpf));
      }
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnObtemImagemArrayPessoas({ payload }) {
  try {
    const array = payload.array;

    const imagensThumbs = yield call(getThumbnailPessoa);

    if (!imagensThumbs.isAxiosError && imagensThumbs.data) {
      yield put(obtemImagemArrayPessoasSucesso(imagensThumbs.data));
    }
    else {
      ////yield put(showAuthMessage('Ocorreu um erro ao retornar os dados do usuario: ' + id));
    }
  } catch (error) {
    ////yield put(showAuthMessage(error));
  }
}

function* fnBuscaUsuarioPlataformaEdicao({ payload }) {
  try {
    const id = payload;
    const usuario = yield call(getUsuarioPlataformaEdicao, id);

    if (!usuario.isAxiosError && usuario.data) {
      ////const arvore = yield call(getArvoreClientePessoa, usuario.data.id, usuario.data.idCliente );

      ////if(arvore.data) {
      yield put(buscaUsuarioPlataformaEdicaoSucesso(usuario.data));
      yield put(limpaValidacoes());
      //// }
      //// else {
      ////   yield put(showAuthMessage('Ocorreu um erro ao retornar a Árvore de Acessos da pessoa: ' + id));
      //// }
    }
    else {
      yield put(showAuthMessage('Ocorreu um erro ao retornar os dados do usuario: ' + id));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnBuscaUsuarioPlataformaLiteEdicao({ payload }) {
  try {
    const id = payload;
    const usuario = yield call(getUsuarioPlataformaLiteEdicao, id);

    if (!usuario.isAxiosError && usuario.data) {
      ////const arvore = yield call(getArvoreClientePessoa, usuario.data.id, usuario.data.idCliente );

      ////if(arvore.data) {
      yield put(buscaUsuarioPlataformaLiteEdicaoSucesso(usuario.data));
      yield put(limpaValidacoes());
      //// }
      //// else {
      ////   yield put(showAuthMessage('Ocorreu um erro ao retornar a Árvore de Acessos da pessoa: ' + id));
      //// }
    }
    else {
      yield put(showAuthMessage('Ocorreu um erro ao retornar os dados do usuario: ' + id));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnListaPerfisPorCliente({ payload }) {
  try {
    const cliente = payload.idCliente;
    const retorno = yield call(getListaPerfisPorCliente, cliente);

    if (!retorno.isAxiosError && retorno.data) {
      yield put(listaPerfisSucesso(retorno.data));
    }
    else {
      yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de perfis do cliente: ' + cliente));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnListaUsuarioPlataforma({ payload }) {
  try {
    const codigoFiltro = payload.codigo;
    const cpfFiltro = payload.cpf;
    const nomeFiltro = payload.nome;
    const retorno = yield call(getListaUsuarioPlataforma, codigoFiltro, cpfFiltro, nomeFiltro);

    if (!retorno.isAxiosError && retorno.data) {
      yield put(listaUsuarioPlataformaSucesso(retorno.data));
    }
    else {
      yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de usuário plataforma'));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnExibeDetalheEstruturaLocal({ payload }) {
  try {
    const local = payload.idLocal;
    const status = payload.status;
    const idAlterar = payload.idAlterar;
    const retorno = yield call(getAcessoAmbienteLocal, local);
    const objetoRetorno = retorno.data;
    const arvoreLocal = objetoRetorno.ambientes;

    if (objetoRetorno && arvoreLocal) {
      yield put(exibeDetalheEstruturaLocalSucesso(arvoreLocal, local, status, idAlterar));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnAplicaFiltro({ payload }) {
  try {
    var filtroAplicar = payload;
    var retorno = null;

    if(filtroAplicar.length >= 3)
    {
      retorno = yield call(getUsuariosPlataformaComFiltro, filtroAplicar);
        
      if (!retorno.isAxiosError && retorno.data) {
/*             yield put(inicioUsuarioPlataformaSucesso(listagemGrid.data, clientes.data, perfis.data, locais.data, paises.data, paisesListaDocumentos.data, locaisVinculosNovos.data, ambientesPerfilTemporario.data)); */
            yield put(aplicarFiltroSucesso(retorno.data))
            yield put(limpaValidacoes());
          }
      else {
        if (retorno.response.status === 401 && retorno.response.config.xsrfHeaderName === "X-XSRF-TOKEN") {
          yield put(userSignOut());
        }
        else {
          yield put(showAuthMessage('Ocorreu um erro ao listar usuariosPlataforma'));
        }
      }
    }
    else if (filtroAplicar.length == 0) {
      retorno = yield call(getUsuariosPlataformaComFiltro, '');
      yield put(aplicarFiltroSucesso(retorno.data))
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnAplicaFiltroCompany({ payload }) {
  try {
    var filtroAplicar = [];
    var id = payload.id;
    var retorno = null;

    if(payload.search.length >= 3)
    {
      if (!isNaN(payload.search)) {
        filtroAplicar = {
          'IdEvento': id,
          'Cpf': payload.search,
          'Codigo': payload.search
        }
      } else {
        filtroAplicar = {
          'IdEvento': id,
          'Nome': payload.search
        }
      }

      retorno = yield call(getPessoasEventosFiltro, filtroAplicar);
        
      if (!retorno.isAxiosError && retorno.data) {
        yield put(iniciaPessoasEventosSucesso(retorno.data, id));
      }
      else {
        if (retorno.response.status === 401 && retorno.response.config.xsrfHeaderName === "X-XSRF-TOKEN") {
          yield put(userSignOut());
        }
        else {
          yield put(showAuthMessage('Ocorreu um erro ao listar usuariosPlataforma'));
        }
      }
    }
    else if (payload.search.length == 0) {
      retorno = yield call(getPessoasEventos, id);
      yield put(iniciaPessoasEventosSucesso(retorno.data, id))
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnCancelaEdicao() {
  const retorno = yield call(getUsuariosPlataformaComFiltro, '');
  yield put(aplicarFiltroSucesso(retorno.data))
}


/*#################################################################################################################*/
/* ---------------------------------- Functions do tipo POST, PUT E DELETE ----------------------------------------*/
/*#################################################################################################################*/


function* fnSalvaUsuarioPlataforma({ payload }) {
  try {

    const usuarioPlataforma = payload;
    var resultado = "";
    var errosAgrupados = "";

    if (usuarioPlataforma.id === 0) {
      const retorno = yield call(adicionaUsuarioPlataformaRequest, usuarioPlataforma);
      resultado = retorno;
    }
    else {
      const retorno = yield call(atualizaUsuarioPlataformaRequest, usuarioPlataforma);
      resultado = retorno;
    }

    if (!resultado.isAxiosError) {
      if (resultado.data) {
        if (resultado.data.sucesso) {
          yield put(iniciaUsuarioPlataforma());
          yield put(salvaUsuarioPlataformaSucesso(resultado.data.mensagem));
        }
        else {
          yield put(exibirMensagemErro(resultado.data.mensagem));
        }
      }
      else {
        if (resultado.response.data) {
          if (resultado.response.data.inconsistencias.length > 0) {
            const inconsistencia = resultado.response.data.inconsistencias[0];
            var propCorrigir = inconsistencia.propriedade;
            var indexPonto = propCorrigir.indexOf(".");
            propCorrigir = propCorrigir.substr(indexPonto + 1);

            yield put(exibirMensagemErro(inconsistencia.mensagem + " - " + propCorrigir));
          }
          else {
            yield put(exibirMensagemErro("Ocorreu um erro interno."));
          }
        }
        else {
          yield put(exibirMensagemErro("Ocorreu um erro interno."));
        }
      }
    }
    else {
      yield put(exibirMensagemErro("Ocorreu um erro interno."));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnSalvaUsuarioPlataformaLite({ payload }) {
  try {
    const usuarioPlataforma = payload;
    var resultado = "";
    var errosAgrupados = "";

    if (usuarioPlataforma.id === null || usuarioPlataforma.id === 0) {
      const retorno = yield call(adicionaUsuarioPlataformaLiteRequest, usuarioPlataforma);
      resultado = retorno;
    }
    else {
      const retorno = yield call(atualizaUsuarioPlataformaLiteRequest, usuarioPlataforma);
      resultado = retorno;
    }
    if (!resultado.isAxiosError) {
      yield put(iniciaUsuarioPlataforma());
      yield put(salvaUsuarioPlataformaSucesso(resultado.data.mensagem));
    }
    else {
      if (resultado.response.data) {
        for (const element of resultado.response.data.notifications) {
          yield put(exibirMensagemErro(`${element.key} - ${element.message}`));
        }      
      } else {
        yield put(exibirMensagemErro("Ocorreu um erro interno."));
      }
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnRemoveClientePessoaPlataforma({ payload }) {
  try {
    const id = payload.id;
    const idCliente = payload.codigosPerfis[0]
    const retorno = yield call(deleteClientePessoaRequest, id, idCliente);
    if (!retorno.isAxiosError && retorno.data) {
      if (retorno.data.sucesso) {
        yield put(iniciaUsuarioPlataforma());
        yield put(removeUsuarioPlataformaSucesso(retorno.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(retorno.data.mensagem));
      }
    }
    else {
      if (retorno.response.data.mensagem) {
        yield put(exibirMensagemErro(retorno.response.data.inconsistencias[0].mensagem));
      }
      else {
        yield put(exibirMensagemErro("Erro desconhecido"));
      }
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnRemoveEventoPessoaPerfil({ payload }) {
  try {
    const id = payload.id;
    const retorno = yield call(deleteEventoPessoaPerfil, id);
    const local = payload.idLocalControladoFixado;

    if (retorno.status === 204) {
      yield put(removeEventoPessoaPerfilSucesso("Removido com Sucesso a Pessoa"));
      const listagemGrid = yield call(getPessoasEventos, local);
      yield put(iniciaPessoasEventosSucesso(listagemGrid.data, local));
    }
    else {
      yield put(exibirMensagemErro("Erro desconhecido"));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnRemoveUsuarioPlataforma({ payload }) {
  try {

    const id = payload;
    const retorno = yield call(deleteUsuarioPlataformaRequest, id);

    if (!retorno.isAxiosError && retorno.data) {
      if (retorno.data.sucesso) {
        yield put(iniciaUsuarioPlataforma());
        yield put(removeUsuarioPlataformaSucesso(retorno.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(retorno.data.mensagem));
      }
    }
    else {
      if (retorno.response.data.mensagem) {
        yield put(exibirMensagemErro(retorno.response.data.inconsistencias[0].mensagem));
      }
      else {
        yield put(exibirMensagemErro("Erro desconhecido"));
      }
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

/*#################################################################################################################*/
/* -------------------------------------------- Chamadas de Funções -----------------------------------------------*/
/*#################################################################################################################*/

export function* iniciaAcoesUsuarioPlataforma() {
  yield takeEvery(INICIA_USUARIO_PLATAFORMA, fnIniciaUsuarioPlataforma);
}

export function* salvaUsuarioPlataforma() {
  yield takeEvery(SALVA_USUARIO_PLATAFORMA, fnSalvaUsuarioPlataforma);
}

export function* iniciaPessoasEvento() {
  yield takeEvery(INICIO_PESSOAS_EVENTO, fnInicioPessoasEvento);
}

export function* relatorioAcessoPessoa() {
  yield takeEvery(RELATORIO_ACESSO_UNICO_PESSOA, fnRelatorioAcessoUnicoPessoasEventos);
}

export function* enviaSmsPessoaEvento(){
  yield takeEvery(ENVIAR_SMS_PESSOA_EVENTO, fnEnviaSmsPessoaEvento);
}
export function* salvaUsuarioPlataformaLite() {
  yield takeEvery(SALVA_USUARIO_PLATAFORMA_LITE, fnSalvaUsuarioPlataformaLite)
}

export function* obtemImagemArrayPessoas() {
  yield takeEvery(OBTEM_IMAGEM_ARRAY_PESSOAS, fnObtemImagemArrayPessoas)
}

export function* buscaPessoaBaseUzer() {
  yield takeEvery(BUSCA_PESSOA_BASE_UZER, fnBuscaPessoaBaseUzer)
}

export function* buscaPessoaLiteBaseUzer() {
  yield takeEvery(BUSCA_PESSOA_BASE_UZER_LITE, fnBuscaPessoaLiteBaseUzer)
}

export function* buscaUsuarioPlataformaEdicao() {
  yield takeEvery(BUSCA_USUARIO_PLATAFORMA_EDICAO, fnBuscaUsuarioPlataformaEdicao);
}

export function* buscaUsuarioPlataformaLiteEdicao() {
  yield takeEvery(BUSCA_USUARIO_PLATAFORMA_LITE_EDICAO, fnBuscaUsuarioPlataformaLiteEdicao);
}

export function* removeUsuarioPlataforma() {
  yield takeEvery(REMOVE_USUARIO_PLATAFORMA, fnRemoveUsuarioPlataforma);
}

export function* removeClientePessoaPlataforma() {
  yield takeEvery(REMOVE_CLIENTE_PESSOA_PLATAFORMA, fnRemoveClientePessoaPlataforma);
}

export function* removeEventoPessoaPerfil() {
  yield takeEvery(REMOVE_EVENTO_PESSOA_PERFIL, fnRemoveEventoPessoaPerfil);
}

export function* listaPerfisPorCliente() {
  yield takeEvery(LISTA_PERFIS_POR_CLIENTE, fnListaPerfisPorCliente);
}

export function* listaUsuarioPlataforma() {
  yield takeEvery(LISTA_USUARIO_PLATAFORMA, fnListaUsuarioPlataforma)
}

export function* exibeDetalheEstruturaLocal() {
  yield takeEvery(EXIBE_DETALHE_ESTRUTURA_LOCAL, fnExibeDetalheEstruturaLocal);
}

export function* aplicaFiltro() {
  yield takeEvery(APLICA_FILTRO, fnAplicaFiltro);  
}

export function* aplicaFiltroCompany() {
  yield takeEvery(APLICA_FILTRO_COMPANY, fnAplicaFiltroCompany);
}

export function* cancelaEdicao() {
  yield takeEvery(CANCELA_EDICAO, fnCancelaEdicao)
}

/*#################################################################################################################*/
/* ------------------------------------------- Registro de Chamadas -----------------------------------------------*/
/*#################################################################################################################*/

export default function* rootSaga() {
  yield all([fork(iniciaAcoesUsuarioPlataforma),
  fork(salvaUsuarioPlataforma),
  fork(salvaUsuarioPlataformaLite),
  fork(buscaUsuarioPlataformaEdicao),
  fork(buscaUsuarioPlataformaLiteEdicao),
  fork(buscaPessoaLiteBaseUzer),
  fork(removeUsuarioPlataforma),
  fork(removeClientePessoaPlataforma),
  fork(removeEventoPessoaPerfil),
  fork(listaPerfisPorCliente),
  fork(listaUsuarioPlataforma),
  fork(buscaPessoaBaseUzer),
  fork(exibeDetalheEstruturaLocal),
  fork(obtemImagemArrayPessoas),
  fork(iniciaPessoasEvento),
  fork(relatorioAcessoPessoa),
  fork(enviaSmsPessoaEvento),
  fork(aplicaFiltro),
  fork(aplicaFiltroCompany),
  fork(cancelaEdicao)
]);
}